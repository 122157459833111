import { getInternationalTime, getInternationalWeekDateTz } from '@utils/dates';
import { v2Links } from '@utils/navigation/links';
import { Carousel, EventCard } from '@v2/components';
import { EventResponse } from '@v2/types';

import Link from 'next/link';

interface Props {
  events: EventResponse[];
  title?: string;
}

export const LeagueCarouselEvents = ({ events, title }: Props) => {
  if (!events?.length)
    return (
      <div className="flex h-[294px] flex-col items-center rounded-lg border border-gray-200">
        <div className="flex h-full flex-col justify-center text-center">
          <h2 className="mb-2 text-xl font-bold text-gray-800">
            No events found
          </h2>
          <p className="text-base font-semibold text-gray-500">
            Please try a different location
          </p>
        </div>
      </div>
    );

  return (
    <div>
      {title && <h1 className="mb-6 text-lg font-bold md:text-2xl">{title}</h1>}

      <Carousel>
        {events?.map(({ id, name, occurs_at_local, venue }) => {
          let evDate = getInternationalWeekDateTz(occurs_at_local, true);
          const date = getInternationalWeekDateTz(occurs_at_local, true);
          const time = getInternationalTime(occurs_at_local, true);
          evDate = evDate.replaceAll(',', '');
          const eventLink = name
            .concat('-', evDate)
            .replaceAll(' ', '-')
            .replaceAll('.', '');

          return (
            <Link
              prefetch={false}
              target="_blank"
              href={`${v2Links.buy}?eventId=${id}&eventLink=${eventLink}`}
              key={id}
              className="flex max-h-[250px] flex-shrink-0 flex-grow-0 basis-72 flex-col"
            >
              <EventCard
                occursAt={occurs_at_local}
                date={date}
                time={time}
                id={id}
                location={venue.name}
                title={name}
                topPick={false}
                bakcgroundImageUrl={venue.image}
              />
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
};
